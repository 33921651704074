<template>
    <div class="b-trip-item">
        <!-- main row -->
        <div class="b-trip-item__main-info-row">
            <ItemImage v-if="item.image" :image="item.image" />

            <div class="b-trip-item__details">
               <ProgramDetails :item="item" />

               <TripItemInformation :item="item" />

               <ExternalLinks :links="item.trip_external_links" v-if="hasExternalLinks"/>

               <!-- not confirmed warning -->
               <span
                  v-if="item.isAwaitingPartnerConfirmation"
                  class="h-awaiting-partner-booking"
                  v-tooltip.top="$t('awaiting_partner_confirmation_explanation')"
               >
                  {{ $t('awaiting_confirmation') }}
               </span>
            </div>

            <!-- dates -->
            <div class="b-trip-item__dates" v-if="item.check_in_date || item.check_out_date">
               <div>
                  <span class="b-trip-item__date-number"> {{ checkInDate }} </span>
                  <span v-if="item.check_in_time && showTime">
                     <br>
                     {{ formattedTime(item.check_in_time) }} {{ $t('hs') }} 
                  </span>
               </div>

               <i class="b-trip-item__date-icon"></i>
            </div>
        </div>

        <Collapsible
            :showHeader="isMobile"
            :isCollapsed="isMobile"
            :key="isMobile"
            v-if="showCollapsibleComponent"
            class="b-trip-item__collapsible-comments-and-links"
        >
            <div class="h-collapsible-target">
               <!-- extras comments -->
                <Comments :item="item"/>

                <!-- sub items -->
                <SubItems
                    :showTime="false"
                    :items="item.linked"
                />

                <!-- files -->
                <AvailableFiles :files="availableFiles" v-if="availableFiles.length" />
            </div>
        </Collapsible>
    </div>
</template>

<script>
   import dateManager from '@/helpers/date.js';
   import { formats } from '@/constants/date.js';
   import SubItems from '@/components/trip_details/SubItems.vue';
   import TripItemInformation from '@/components/trip_details/TripItemInformation.vue';
   import Comments from '@/components/trip_details/Comments.vue';
   import AvailableFiles from '@/components/trip_details/AvaibleFiles.vue';
   import ExternalLinks from '@/components/trip_details/ExternalLinks.vue';
   import ProgramDetails from '@/components/trip_details/ProgramDetailsContent.vue';
   import Tooltip from 'primevue/tooltip';
   import tripDetailItem from '@/mixins/tripDetailItem.js';
   import Collapsible from '@/components/shared/Collapsible.vue';
   import ItemImage from '@/components/trip_details/ItemImage.vue';

   export default {
      mixins: [ tripDetailItem ],
      components: {
         AvailableFiles,
         Collapsible,
         Comments,
         ExternalLinks,
         ItemImage,
         ProgramDetails,
         SubItems,
         TripItemInformation,
      },
      directives: {
         'tooltip': Tooltip
      },
      computed: {
         checkInDate() {
            return dateManager.format(this.item.check_in_date, formats.DATABASE, formats.DISPLAY_CHECK);
         },
      },
      methods: {
         formattedTime(time) {
            return dateManager.format(time, formats.TIME_WITH_SECONDS, formats.TIME);
         }
      },
   };
</script>
