<template>
   <div class="b-trip-item">
      <!-- main row -->
      <div class="b-trip-item__main-info-row">
         <ItemImage v-if="item.image" :image="item.image" emptyIcon="icon__bed-full"/>

         <div class="b-trip-item__details" style="max-width: 100%; overflow: auto;">
            <AccommodationDetails :item="item" />

            <TripItemInformation :item="item" />

            <ExternalLinks v-if="hasExternalLinks" :links="item.trip_external_links"/>

            <!-- meal plans -->
            <template v-if="showMealPlans">
               <div class="b-trip-item__meal-plan"> {{ $t('meal_plans') }} </div>

               <MealPlanTable
                  :disabled="true"
                  :tripDays="tripDays"
                  :mealPlansProp="mealPlans"
               />

               <div class="b-trip-item__meal-plan-text" v-if="!hasValidOffer">
                  {{ $t("meal_plan_not_clickable_text") }}

                  <div
                     class="b-trip-item__meal-plan-text-link"
                     @click="goToDashboardSection(); setMealPlansCollapse()"
                  >
                     {{ $t("meal_plan_not_clickable_link") }}
                  </div>
               </div>
            </template>
         </div>

         <!-- dates -->
         <div class="b-trip-item__dates">
            <div>
               <span class="b-trip-item__date-number"> {{ checkInDate }} </span>
               <br>
               <span v-if="displayCheckoutDate" class="b-trip-item__date-number">
                  {{ checkOutDate }}
               </span>
            </div>

            <i class="b-trip-item__date-icon"></i>
         </div>
      </div>

      <Collapsible
         :key="isMobile"
         :showHeader="isMobile"
         :isCollapsed="isMobile"
         v-if="showCollapsibleComponent"
         class="b-trip-item__collapsible-comments-and-links"
      >
         <div class="h-collapsible-target">

            <!-- accommodation and extras comments -->
            <Comments :item="item"/>

            <!-- sub items -->
            <SubItems :showTime="false" :items="item.linked"/>

            <!-- files -->
            <AvailableFiles :files="availableFiles" v-if="availableFiles.length" />
         </div>
      </Collapsible>
   </div>
</template>

<script>
   import dateManager from '@/helpers/date.js';
   import { formats } from '@/constants/date.js';
   import navigation from '@/mixins/navigation.js';
   import tripDetailItem from '@/mixins/tripDetailItem.js';
   import SubItems from '@/components/trip_details/SubItems';
   import Comments from '@/components/trip_details/Comments.vue';
   import Collapsible from '@/components/shared/Collapsible.vue';
   import MealPlanTable from '@/components/trip_details/MealPlanTable.vue';
   import AvailableFiles from '@/components/trip_details/AvaibleFiles.vue';
   import ExternalLinks from '@/components/trip_details/ExternalLinks.vue';
   import AccommodationDetails from '@/components/trip_details/AccommodationDetailsContent.vue';
   import TripItemInformation from '@/components/trip_details/TripItemInformation.vue';
   import ItemImage from '@/components/trip_details/ItemImage.vue';
   import { mapState, mapMutations } from 'vuex';

   export default {
      mixins: [ navigation, tripDetailItem ],
      components: {
         AccommodationDetails,
         AvailableFiles,
         Collapsible,
         Comments,
         ExternalLinks,
         ItemImage,
         MealPlanTable,
         SubItems,
         TripItemInformation,
      },
      methods: {
         ...mapMutations([
            'setMealPlansCollapse',
         ]),
      },
      computed: {
         ...mapState([
            'mealPlanData',
            'offers',
         ]),
         checkInDate() {
            return dateManager.format(this.item.check_in_date, formats.DATABASE, formats.DISPLAY_CHECK);
         },
         checkOutDate() {
            return dateManager.format(this.item.check_out_date, formats.DATABASE, formats.DISPLAY_CHECK);
         },
         displayCheckoutDate() {
            return this.checkInDate != this.checkOutDate
         },
         mealPlans() {
            if (!this.mealPlanData) {
               return null;
            }

            return this.mealPlanData.data[this.item.id].mealPlans;
         },
         showMealPlans() {
            return !!this.mealPlans;
         },
         tripDays() {
            return this.mealPlanData.tripDatePeriod;
         },
         hasValidOffer() {
            if(this.offers == null) {
               return false;
            }

            return this.offers.last.status === 'sent';
         },
      }
   };
</script>
