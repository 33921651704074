import { mapState } from 'vuex';

export default {
    props: {
        item: {
            type: Object,
            default() {
                return null;
            },
        },
    },
    computed: {
        ...mapState([
            'isMobile',
        ]),
        availableFiles() {
            let availablefiles = [];
            let allItemFiles = this.allItemFiles(this.item);
            if (allItemFiles) {
                allItemFiles.forEach((file) => {
                    let file_name = file.name;
                    let file_extension = (file_name.lastIndexOf('.') < 1)
                        ? ''
                        : file_name.split('.').slice(-1);
                    let image_extensions = ['jpg', 'jpeg', 'png', 'bmp', 'gif'];
                    if (image_extensions.includes(file_extension[0])) {
                        file_extension[0] = 'img';
                    }
                    let obj = {
                        "name": file_name,
                        "path": file.path,
                        "extension": file_extension[0]
                    };
                    availablefiles.push(obj);
                });
            }
            return availablefiles;
        },
        showTime() {
            return this.item.show_time;
        },
        hasComments() {
            return this.item.comments && this.item.comments.length;
        },
        hasExternalLinks() {
            return this.item.trip_external_links && this.item.trip_external_links.length > 0;
         },
        hasLinked() {
            return this.item.linked;
        },
        hasTripFiles() {
            return this.item.trip_files && this.item.trip_files.length;
        },
        showCollapsibleComponent() {
            // TODO @dev review that the way I'm checking for hasLinked is ok. It was "this.hasLinked" but that always returned true
            return this.hasComments || Object.keys(this.hasLinked).length || this.hasTripFiles;
        },
    },
    methods: {
        allItemFiles(item) {
           let allFiles = item.trip_files;
            if (item.linked) {
                item.linked.forEach(linkedItem => {
                    allFiles = allFiles.concat(linkedItem.trip_files);
                });
            }
            return allFiles;
        },
    },
};