<template>
   <transition name="slide-fade">
      <div>
         <!-- title -->
         <div class="h-title">
            {{ $t("trip_detail") }}
         </div>

         <!-- accomodation -->
         <div class="p-trip-details__section">
            <div class="p-trip-details__section-icon icon__service-bell-full"></div>

            <template v-for="(item, index) in accommodations" :key="`hotel-${index}`">
               <AccommodationTripItem v-if="!item.is_alternative" :item="item"/>
            </template>
         </div>

         <!-- programs -->
         <div class="p-trip-details__section">
            <div class="p-trip-details__section-icon icon__camera-full"></div>

            <template v-for="(item, index) in programs" :key="`program-${index}`">
               <ProgramTripItem :item="item"/>
            </template>
         </div>

         <!-- transportation -->
         <div class="p-trip-details__section">
            <div class="p-trip-details__section-icon icon__train-full"></div>

            <template v-for="(item, index) in transportation" :key="`transport-${index}`"  >
               <TransportTripItem :item="item" v-if="!item.is_alternative" />
            </template>
         </div>

         <!-- extras -->
         <div class="p-trip-details__section">
            <div class="p-trip-details__section-icon icon__asterisk"></div>

            <template v-for="(item, index) in extras" :key="`${index}`">
               <ExtraTripItem
                  v-if="item.visible && !item.is_alternative"
                  :item="item"
                  :show-phone="false"
               />
            </template>
         </div>
      </div>
   </transition>
</template>

<script>
   import stopLoadingOnMounted from '@/mixins/stopLoadingOnMounted.js';
   import ExtraTripItem from '@/components/trip_details/ExtraTripItem.vue';
   import ProgramTripItem from '@/components/trip_details/ProgramTripItem.vue';
   import TransportTripItem from '@/components/trip_details/TransportTripItem.vue';
   import AccommodationTripItem from '@/components/trip_details/AccommodationTripItem.vue';
   
   import { mapState } from 'vuex';

   export default {
      components: {
         AccommodationTripItem,
         ExtraTripItem,
         ProgramTripItem,
         TransportTripItem,
      },
      mixins: [
         stopLoadingOnMounted,
      ],
      computed: {
         ...mapState({
            extras: state => state.tripDetails.extras,
            programs: state => state.tripDetails.programs,
            accommodations: state => state.tripDetails.accommodations,
            transportation: state => state.tripDetails.transportation,
            destinations: state => state.tripDetails.destinations,
         }),
      },
   };
</script>

<style lang="less">
   @import "./../assets/styles/trip-item.less";

   .p-trip-details {
      --section-icon-size: 50px;

      &__section {
         margin-top: var(--section-icon-size);
         margin-left: 25px;
         position: relative;
      }

      &__section-icon {
         position: absolute;
         width: var(--section-icon-size);
         height: $width;
         top: calc(~"var(--section-icon-size) / 2 * -1");
         left: $top;
         border-radius: 100%;
         background-color: var(--color-ok);
         color: white;
         .h-flex-col-center;
         .align-items(center);

         &:before {
            font-size: calc(~"var(--section-icon-size) / 2");
         }
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         &__section {
            margin-left: 0;
         }

         &__icon {
            top: calc(~"var(--section-icon-size) / 1.5 * -1");
            left: 50%;
            .transform(translateX(-50%));
         }
      }
   }
</style>
