<template>
   <div class="b-trip-item is-transportation" >
      <!-- main row -->
      <div class="b-trip-item__main-info-row">
         <!-- icon  -->
         <div class="b-trip-item__image-icon" :class="iconClass"></div>

         <div class="b-trip-item__details">
            <!-- title -->
            <div class="b-trip-item__title">
               {{ item.title }}
               <span class="b-trip-item__city" v-if="item.location">
                  {{ item.location }}
               </span>
               <template v-if="item.has_alternatives && !readOnlyNoSensitiveDataMode">
                  <br>
                  <a
                     target="_blank"
                     :href="this.offers.last.link"
                     class="b-timeline-item__alternative-warning"
                  >
                     {{ $t("check_offer_for_alternatives") }}
                  </a>
               </template>
            </div>

            <!-- pax -->
            <div class="b-trip-item__detail-item" v-if="item.pax">
               <i class="b-trip-item__detail-icon icon__participants-selected"></i>
               <span class="font-bold"> {{ item.pax }} </span>&nbsp;
               {{ $t("participants") }}
            </div>

            <!-- phone -->
            <div class="b-trip-item__detail-item" v-if="item.contact_phone">
               <i class="b-trip-item__detail-icon icon__phone-full"></i>
               {{ item.contact_phone }}
            </div>

            <ExternalLinks v-if="hasExternalLinks" :links="item.trip_external_links"/>
         </div>

         <!-- dates -->
         <div class="b-trip-item__dates" v-if="item.check_in_date || item.check_out_date">
            <div>
                <div class="b-trip-item__subtitle margin-0">
                    {{ item.origin_station }}
                    <i class="h-arrow"></i>
                    {{ item.destination_station }}
                </div>

                <span class="b-trip-item__date-number"> {{ checkInDate }} </span>

                <template v-if="item.check_in_time">
                    <br>
                    {{ $t("departure_time") }}: {{ item.departureTripDepartureTime }}
                    -
                    {{ $t("arrival_time") }}: {{ item.departureTripArrivalTime }}
                </template>

                <br>

                <div v-if="item.is_round_trip" class="b-trip-item__subtitle">
                    {{ item.destination_station }}
                    <i class="h-arrow"></i>
                    {{ item.origin_station }}
                </div>

                <span v-if="displayCheckoutDate" class="b-trip-item__date-number">{{ checkOutDate }}</span>

                <template v-if="item.is_round_trip">
                    <br>
                    {{ $t("departure_time") }}: {{ item.returnTripDepartureTime }}
                        -
                    {{ $t("arrival_time") }}: {{ item.returnTripArrivalTime }}
                </template>
            </div>

            <i class="b-trip-item__date-icon"></i>
         </div>
      </div>

      <Collapsible
         :showHeader="isMobile"
         :isCollapsed="isMobile"
         :key="isMobile"
         v-if="showCollapsibleComponent"
         class="b-trip-item__collapsible-comments-and-links"
      >
         <div class="h-collapsible-target">
            <!-- transportation departure station -->
            <div class="b-trip-item__stations" v-if="hasDepartureComment">
               {{ item.origin_station }}
               <i class="h-arrow"></i>
               {{ item.destination_station }}
            </div>

            <!-- comments -->
            <Comments
               :item="item"
               v-if="hasDepartureComment"
               :isDepartureTransportComment="true"
            />

            <!-- transportation return station -->
            <div class="b-trip-item__stations" v-if="item.is_round_trip && hasReturnComment">
               {{ item.destination_station }}
               <i class="h-arrow"></i>
               {{ item.origin_station }}
            </div>

            <!-- return trip transport comments -->
            <Comments
               v-if="hasReturnComment && item.is_round_trip"
               :item="item"
               :isReturnTransportComment="true"
            />

            <!-- sub items -->
            <sub-items :showTime="false" :items="item.linked"/>

            <!-- files -->
            <available-files :files="availableFiles" v-if="availableFiles.length" />
         </div>
      </Collapsible>
   </div>
</template>

<script>
   import moment from 'moment';
   import tripDetailItem from '@/mixins/tripDetailItem.js';
   import SubItems from '@/components/trip_details/SubItems.vue';
   import Comments from '@/components/trip_details/Comments.vue';
   import Collapsible from '@/components/shared/Collapsible.vue';
   import AvailableFiles from '@/components/trip_details/AvaibleFiles.vue';
   import ExternalLinks from '@/components/trip_details/ExternalLinks.vue';
   import { mapState } from 'vuex';

   export default {
      mixins: [ tripDetailItem ],
      components: {
         AvailableFiles,
         Comments,
         Collapsible,
         ExternalLinks,
         SubItems,
      },
      computed: {
         ...mapState([
            'readOnlyNoSensitiveDataMode',
            'offers',
         ]),
         checkInDate() {
            return moment(this.item.check_in_date, 'YYYY-MM-DD').lang("de").format('ddd DD.MM.YYYY');
         },
         checkOutDate() {
            return moment(this.item.check_out_date, 'YYYY-MM-DD').lang("de").format('ddd DD.MM.YYYY');
         },
         displayCheckoutDate() {
            if (!this.item.is_round_trip) {
               return false;
            }
            return this.checkInDate != this.checkOutDate
         },
         hasDepartureComment() {
            return this.item.comments.filter((comment) =>
                comment.date == this.item.check_in_date
            ).length > 0;
         },
         hasReturnComment() {
            return this.item.comments.filter((comment) =>
                comment.date_end >= this.item.check_out_date
            ).length > 0;
         },
         isTrain() {
            return this.item.transportation_type == 'train'
         },
         isBus() {
            return this.item.transportation_type == 'bus'
         },
         isPlane() {
            return this.item.transportation_type == 'plane'
         },
         iconClass() {
            if (this.item.transportation_type == 'train') {
               return 'icon__train-full'
            }

            if (this.item.transportation_type == 'plane') {
               return 'icon__plane-full'
            }

            if (this.item.transportation_type == 'bus') {
               return 'icon__bus-full'
            }
            return '';
         },
      },
   };
</script>
