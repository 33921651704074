<template>
    <div class="b-subitems">
        <div v-for="(item,index) in items" class="b-subitems__row"   :key="index" >
            <template v-if="showSubItem(item)">
                <i class="b-subitems__icon icon__tick"></i>

                <div class="b-subitems__row-content">
                    <div class="b-subitems__row-top-wrap">
                        <div class="b-subitems__title">
                            {{ item.title }}
                        </div>

                        <div class="b-trip-item__dates">
                            <div v-if="item.service_dates">
                                <span
                                    v-for="(dateSpan, index) in item.service_dates"
                                    :key="index"
                                    v-html="dateHtml(dateSpan, item.service_dates.length == index + 1)"
                                    class="b-trip-item__date-number"
                                >
                                </span>
                            </div>
                            <span
                                v-if="!item.service_dates"
                                v-html="date(item.min_date, item.max_date)"
                                class="b-trip-item__date-number"
                            >
                            </span>
                            <div v-if="showTime && item.time != '00:00'">
                                    {{ item.time }} hs
                            </div>
                            <i class="b-trip-item__date-icon"></i>
                        </div>
                    </div>

                    <!-- trip info -->
                    <div
                        class="b-subitems__text"
                        v-if="item.trip_information"
                        v-html="item.trip_information"
                    >
                    </div>

                    <comments :item="item"/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
   import Comments from '@/components/trip_details/Comments.vue';
   import moment from 'moment';

   export default {
      components: {
         Comments,
      },
      props: {
         items: {},
         showTime: {
            type: Boolean,
            default: false,
         }
      },
      methods: {
         date(minDate, maxDate) {
            minDate = moment(minDate, 'YYYY-MM-DD').lang("de").format('DD.MM.YYYY');
            maxDate = moment(maxDate, 'YYYY-MM-DD').lang("de").format('DD.MM.YYYY');

            if (minDate == maxDate) {
               return minDate;
            }

            return `${minDate} <i class="h-arrow"></i> ${maxDate}`;
         },
         dateHtml(dateSpan, isLast = false) {
            let htmlElement = '';
            if (!dateSpan.to) {
               htmlElement += `<span style="margin-left:10px"> ${dateSpan.from} </span>`;
            } else {
               htmlElement += `<span style="margin-left:10px"> ${dateSpan.from} <i class="h-arrow"></i> ${dateSpan.to} </span>`;
            }

            return isLast ? htmlElement : htmlElement + ',';
         },
         serviceDates(item) {
            let serviceDatesString = '';
            item.service_dates.forEach((item) => serviceDatesString += `${item}, `);

            return serviceDatesString.slice(0, -2);
         },
         showSubItem(item) {
            return item && !item.is_meal_plan && item.visible;
         }
      },
      computed: {
      }
   };
</script>

<style lang="less">
   .b-subitems {
       margin-top: 20px;

       &__row {
           margin-bottom: 20px;
           .h-flex-row-left-top;
       }

       &__icon {
           font-size: calc((30 / var(--base-value)) * 1rem);
           color: var(--color-ok);
           margin: 5px 15px;
           .flex-shrink(0);
       }

       &__row-content {
           padding: 10px 20px;
           background-color: var(--color-gray-light);
           border: solid 1px var(--color-gray-4);
           .flex-grow(1);
       }

       &__row-top-wrap {
           .h-flex-row-left-center;
       }

       &__title {
           line-height: 1;
           .h-highlight-text;
           .flex-grow(1);
       }

       &__text {
           margin-top: 8px;
           padding-bottom: 10px;
           .flex-grow(1);

           p {
               margin: 0;
           }
       }

       .b-trip-item__dates {
           .h-flex-row-left-top;
       }

       @media only screen and (max-width : @breakpoint-mobile) {
           .h-flex-col-center;

           &__row {
               .h-flex-col;
           }

           &__icon {
               margin-bottom: 10px;
               .align-self(center);
           }

           &__row-content {
               padding-top: 20px;
           }

           &__row-top-wrap {
               .h-flex-col;
               .align-items(flex-start);
           }

           .b-trip-item__dates {
               margin-top: 5px;
           }
       }
   }
</style>
