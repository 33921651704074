<template>
    <div class="b-comments"
         v-if="showComments"
    >
        <div class="b-comments__rows-wrap">
            <div
                class="b-comments__row"
                v-for="(comment,index) in comments"
                :key="index"
            >
                <div class="b-comments__text"
                     v-html="formattedComment(comment.comment)">
                </div>

                <!-- hidden as requested in ticket BNY-100185 -->
                <div v-if="false" class="b-comments__clarification">
                    {{ comment.itemType }}
                    &nbsp;-&nbsp;
                    {{ formattedDate(comment.date) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { typeName as serviceName } from '@/constants/service';
    import moment from 'moment';

    export default {
        props: {
            item: {},
            isReturnTransportComment: {
               type: Boolean,
               default: false
            },
            isDepartureTransportComment: {
               type: Boolean,
               default: false
            },
        },
        computed: {
            comments() {
                if (this.isTransportation && this.isDepartureTransportComment) {
                  return this.departureTransportComment;
                }
                if (this.isTransportation && this.isReturnTransportComment) {
                  return this.returnTransportComment;
                }

                return this.item.comments;
            },
            isTransportation() {
               return this.item.type == serviceName.TRANSPORTATION;
            },
            departureTransportComment() {
               if (!this.isTransportation) {
                  return [];
               }

               return this.item.comments.filter((comment) =>
                  comment.date == this.item.check_in_date
               );
            },
            returnTransportComment() {
               if (!this.isTransportation) {
                  return [];
               }

               return this.item.comments.filter((comment) =>
                  comment.date_end >= this.item.check_out_date
               );
            },
            showComments() {
               return this.item && this.item.comments && this.item.comments.length !== 0;
            }
        },
        methods: {
            formattedDate(date) {
                return moment(date).format('DD.MM.YYYY');
            },
            formattedComment(comment) {
                var str = comment
                var newStr = str.replace(/\n/g, '<br />');
                return newStr;
            }
        }
    };
</script>

<style lang="less">
    .b-comments {
       background-color: var(--color-gray-light);
       border: solid 1px var(--color-gray-4);
       padding: 10px 20px;
       margin-top: 20px;
       .h-flex-row-left-top;

       &__rows-wrap {
           .flex-grow(1);
       }

       &__row {
           padding: 4px 0;
           border-bottom: solid 1px var(--color-gray-4);
           margin-bottom: 10px;
           .h-flex-row-left-top;

           &:last-child {
               border-bottom: none;
               margin-bottom: 0;
               padding-bottom: 0;
           }
       }

       &__text {
           .flex-grow(1);
       }

       &__title {
           font-size: calc((15 / var(--base-value)) * 1rem);
           line-height: 1;
           .h-font-bold;
       }

       &__clarification {
           text-align: right;
           color: var(--color-gray-3);
           max-width: 350px;
       }

       @media only screen and (max-width : @breakpoint-mobile) {
           .h-flex-col-center;
           .align-items(center);

           &__icon {
               margin-bottom: 10px;
           }

           &__row {
               .h-flex-col;
               margin-left: 0;
           }

           &__clarification {
               text-align: left;
           }
       }
   }
</style>
