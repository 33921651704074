<template>
   <img
      v-if="image != '/img/onlinecatalog/no-image.jpg'"
      class="b-trip-item__picture"
      :src="image"
   />

   <!-- icon (when iage is a dummy one) -->
   <div 
      v-if="image == '/img/onlinecatalog/no-image.jpg' && emptyIcon" 
      class="b-trip-item__image-icon"
      :class="emptyIcon"
   >
   </div>
</template>

<script>
   export default {
      props: {
         image: {
            type: String,
         },
         emptyIcon: {
            type: String,
            default: 'icon__blocked',
         }
      },
   };
</script>