<template>
   <div class="b-external-links">
      <div class="b-external-links__title">
         {{ $t("for_more_info_please_check") }}
      </div>

      <a
         v-for="(link, index) in links"
         :href="link.href"
         :key="index"
         target="_blank"
         class="b-external-links__link"
      >
         {{ link.title }}
      </a>
   </div>
</template>

<script>
   export default {
      props: {
         links: [],
      },
   };
</script>

<style lang="less">
   .b-external-links {
      margin-top: 20px;

      &__title {
         margin-top: 20px;

         a {
            display: block;
            margin-bottom: 5px;
         }
      }

      &__link {
         color: var(--color-red);
         text-decoration: none;
         margin-bottom: 5px;
         .h-flex-row-left-center;

         &:before {
            content: '';
            width: 5px;
            height: 5px;
            background-color: hsl(var(--color-red-hs) 80%);
            margin: 0 10px;
            border-radius: 100%;
         }
      }
   }
</style>