<template>
   <!-- title -->
   <div class="b-trip-item__title">
      {{ item.title }}
      <span class="b-trip-item__city" v-if="item.location">
         {{ item.location }}
      </span>
      <a
         class="b-trip-item__oc-link"
         :href="item.oc_url"
         v-if="item.oc_url && false"
         target="_blank"
      >
      </a>
      <template v-if="item.has_alternatives && !readOnlyNoSensitiveDataMode">
         <br>
         <a
            target="_blank"
            :href="offers.last.link"
            class="b-timeline-item__alternative-warning"
         >
            {{ $t("check_offer_for_alternatives") }}
         </a>
      </template>
   </div>

   <!-- pax -->
   <div class="b-trip-item__detail-item">
      <i class="b-trip-item__detail-icon icon__participants-selected"></i>
      <span class="font-bold">{{ item.pax }}</span>&nbsp;
      {{ $t("participants") }}
   </div>

   <!-- room distribution -->
   <div class="b-trip-item__detail-item">
      <i class="b-trip-item__detail-icon icon__bed"></i>
      <div>
         <template  v-for="(room,index) in item.room_structure" :key="index">
            {{ room.pax }} {{ $t("persons_in") }} {{ room.type }} <br>
         </template>
      </div>
   </div>
</template>

<script>
   import {mapState} from 'vuex';

   export default {
      props: {
         item: {
            type: Object,
         },
      },
      computed: {
         ...mapState([
            'readOnlyNoSensitiveDataMode',
            'offers',
         ]),
      }
   };
</script>
