<template>
   <div class="b-trip-item">

      <!-- main row -->
      <div class="b-trip-item__main-info-row">
         <ItemImage v-if="item.image" :image="item.image"/>

         <div class="b-trip-item__details">
            <!-- title -->
            <div class="b-trip-item__title"> {{ item.title }} </div>

            <TripItemInformation :item="item" />

            <ExternalLinks 
               :links="item.trip_external_links" 
               v-if="item.trip_external_links && item.trip_external_links.length > 0" 
            />
         </div>
      </div>
         <div class="h-collapsible-target">
            <!-- comments -->
            <Comments :item="item"/>

            <!-- sub items -->
            <SubItems :showTime="false" :items="item.linked"/>

            <!-- files -->
            <AvailableFiles :files="availableFiles" v-if="availableFiles.length" />
         </div>
   </div>
</template>

<script>
   import SubItems from '@/components/trip_details/SubItems.vue';
   import Comments from '@/components/trip_details/Comments.vue';
   import tripDetailItem from '@/mixins/tripDetailItem.js';
   import AvailableFiles from '@/components/trip_details/AvaibleFiles.vue';
   import ExternalLinks from '@/components/trip_details/ExternalLinks.vue';
   import TripItemInformation from '@/components/trip_details/TripItemInformation.vue';
   import ItemImage from '@/components/trip_details/ItemImage.vue';

   export default {
      mixins: [ tripDetailItem ],
      components: {
         AvailableFiles,
         Comments,
         ExternalLinks,
         ItemImage,
         SubItems,
         TripItemInformation,
      },
   };
</script>
