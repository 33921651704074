<template>
    <div class="b-available-files">
        <div class="b-available-files__name">
            {{ $t("available_files") }}:
        </div>

        <a
            v-for="(file,index) in files"
            :href="file.path"
            target="_blank"
            class="b-available-files__item"
            :key="index"
        >
            <div class="b-available-files__item-extension">
                {{ file.extension }}
            </div>

            <div class="b-available-files__item-name">
                {{ file.name }}
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            files: {},
        },
    };
</script>

<style lang="less">
    .b-available-files {
        border-top: solid 1px var(--color-gray-4);
        padding-top: 15px;
        .h-flex-row-left-center;

        &__name {
            font-size: calc((13 / var(--base-value)) * 1rem);
            color: var(--color-gray-2);
        }

        &__item-extension {
            color: white;
            background-color: var(--color-red);
            padding: 3px;
            margin-right: 5px;
            text-transform: uppercase;
            font-size: calc((10 / var(--base-value)) * 1rem);
            line-height: 1;
            position: relative;
            top: -2px;
            .h-font-bold;
        }

        &__item-name {
            font-size: calc((15 / var(--base-value)) * 1rem);
            color: var(--color-gray);
            line-height: 1;
            position: relative;
            top: -1px;
            .transition;
        }

        &__item {
            margin-left: 20px;
            text-decoration: none;
            .h-flex-row-left-center;

            &:hover,
            &:focus,
            &:active {
                .b-available-files__item-name {
                    color: var(--color-red);
                }
            }
        }

        @media only screen and (max-width : @breakpoint-mobile) {
            .h-flex-col;

            &__name {
                width: 100%;
            }

            &__item {
                margin: 15px 0 0 0;
                width: 100%;
            }
        }
    }
</style>