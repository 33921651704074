<template>
   <template v-if="!isExtra">
      <div class="b-trip-item__trip-info"
         v-if="item.trip_information"
         v-html="item.trip_information"
      >
      </div>

      <!-- more info link -->
      <div v-if="item.description_is_trimmed" class="b-trip-item__trip-info">
         <a 
            class="h-link"
            target="_blank"
            :href="item.oc_url"
            v-if="false"
         >
            {{ $t("check_details_in_online_catalog") }}
         </a>
      </div>
   </template>

   <template v-else>
         <!-- trip information or description -->
         <div
            class="b-trip-item__trip-info"
            v-if="item.trip_information && !item.description_is_trimmed"
            v-html="item.trip_information"
         >
         </div>

         <template v-if="item.description_is_trimmed">
            <!-- trimmed description -->
            <Collapsible
               :showHeader="false"
               :isCollapsed="!isExtraTrimmedDescriptionVisible"
               :key="isExtraTrimmedDescriptionVisible"
               ref="trimmedDescription"
            >
               <div class="h-collapsible-target">
                  <div
                     class="b-trip-item__trip-info"
                     v-if="item.trip_information"
                     v-html="item.trip_information"
                  >
                  </div>
               </div>
            </Collapsible>

            <!-- full description -->
            <Collapsible
               :showHeader="false"
               :isCollapsed="!isExtraFullDescriptionVisible"
               :key="isExtraFullDescriptionVisible"
               ref="fullDescription"
            >
               <div class="h-collapsible-target">
                  <div v-html="item.description_full"></div>
               </div>
            </Collapsible>

            <a
               class="h-link"
               href="javascript:void(0);"
               @click="manageExtrasDescriptionVisibility"
            >
               <template v-if="isExtraTrimmedDescriptionVisible">
                  {{ $t("show_full") }}
               </template>
               <template v-else>
                  {{ $t("show_less") }}
               </template>
            </a>
         </template>
   </template>
</template>

<script>

   import Collapsible from '@/components/shared/Collapsible.vue';
   import {typeName as serviceName} from '@/constants/service';

   export default {
         
      components: {
         Collapsible
      },
      props: {
         item: {
            type: Object,
            default() {
               return {}
            },
         }
      },
      data() {
         return {
            isExtraTrimmedDescriptionVisible: true,
            isExtraFullDescriptionVisible: false,
         }
      },
      methods: {
         manageExtrasDescriptionVisibility() {
            if (this.isExtraTrimmedDescriptionVisible) {
               this.$refs.trimmedDescription.toggle();

               setTimeout(() => {
                  this.$refs.fullDescription.toggle();
               }, 400);

            } else {
               this.$refs.fullDescription.toggle();

               setTimeout(() => {
                  this.$refs.trimmedDescription.toggle();
               }, 400);
            }

            this.isExtraTrimmedDescriptionVisible = !this.isExtraTrimmedDescriptionVisible;
            this.isExtraFullDescriptionVisible = !this.isExtraFullDescriptionVisible;
         }
      },
      computed: {
         isExtra() {
            return this.item.type == serviceName.EXTRA;
         }
      }
   };
</script>
